<template>
  <v-container>
    <v-btn color="primary" @click="backToCase">Back</v-btn>
   <PatientIntakeForm v-if="intakeStatus" :intakeStatus="intakeStatus"/>
  </v-container>
</template>
<script>

import PatientIntakeForm from '@/components/PatientIntake/PatientIntakeForm'
import { mapActions } from 'vuex'
import IntakeStatus from '@/js/PatientIntake/IntakeStatus'

export default {
  name: 'ClinicIntakeView',
  components: { PatientIntakeForm },
  data: () => ({
    intakeStatus: ''
  }),
  created () {
    this.getCurrentIntake()
  },
  methods: {
    ...mapActions('PatientIntake', ['setStep', 'setAllInfo']),
    backToCase () {
      this.$router.push({ name: 'CaseView', params: { caseId: this.$route.params.caseId.toString() } })
    },
    getCurrentIntake () {
      this.$store.dispatch('PatientIntake/getIntake', this.$route.params.uuid)
        .then(response => {
          if (response.intake_status !== IntakeStatus.NOT_STARTED) {
            this.setAllInfo(response.intake)
            this.setStep(response.step)
          }
          this.intakeStatus = response.intake_status
        })
    }
  }
}
</script>
